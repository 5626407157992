<template lang="pug">
#app
  Header
  router-view.pb-md-5
  ModalLogin
  ModalSignup
  ModalEmpresa
  Footer

  .toast-container.position-fixed.top-0.start-50.translate-middle.px-3.pt-5.mt-5
    #msg.toast.fade.text-bg-warning(role='alert' aria-live='assertive' aria-atomic='true')
      .toast-header
        i.bi.bi-bell-fill.me-1        
        strong.me-auto eBIZ Go        
        button.btn-close(type='button' data-bs-dismiss='toast' aria-label='Close')
      .toast-body {{ $store.state.MSG || 'mensaje por defecto' }}
</template>

<script>
import Header from "@/components/Header";
import ModalLogin from "@/components/ModalLogin";
import ModalSignup from "@/components/ModalSignup";
import ModalEmpresa from "@/components/ModalEmpresa";
import Footer from "@/components/Footer";

export default {
  components: {
    Header,
    ModalLogin,
    ModalSignup,
    ModalEmpresa,
    Footer
  }
}
</script>
