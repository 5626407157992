<template lang="pug">
div
  .position-fixed.w-100(style="z-index:1")
    .bg-success
      .container.py-3
        .row.align-items-center
          .col-12
            .d-flex.align-items-center
              .input-group-sm.input-group.border-0.p-1.bg-white.shadow-sm.rounded-3
                span.input-group-text.bg-white.border-0: i.icofont-search
                input.form-control.bg-white.border-0.ps-0(v-model="search" type='search' placeholder='Buscar Tienda')

  .bg-white.pt-5
    .container.pt-4
      .row
        .col-12
          .py-4.d-flex.align-items-center.gap-4
            .d-none.d-md-block
              h1.img-fluid.rounded-pill.bg-light.p-4: i.bi.bi-shop
            .text-md-start
              h2.mb-0.fw-bold eBIZ Go, tu tienda en línea
              p.lead.m-0
                i.bi.bi-shop.me-2
                span {{ tiendas.length }} tiendas

  .main-banner.bg-white.pb-4
    .container
      //- #carouselExampleFade.carousel.slide.carousel-fade.mb-4(data-bs-ride='carousel')
        .carousel-inner.rounded
          .carousel-item.active: img.d-block.w-100(src='/assets/img/banner1.png' alt='...')
          .carousel-item: img.d-block.w-100(src='/assets/img/banner2.png' alt='...')
        button.carousel-control-prev(type='button' data-bs-target='#carouselExampleFade' data-bs-slide='prev')
          span.carousel-control-prev-icon(aria-hidden='true')
          span.visually-hidden Previous
        button.carousel-control-next(type='button' data-bs-target='#carouselExampleFade' data-bs-slide='next')
          span.carousel-control-next-icon(aria-hidden='true')
          span.visually-hidden Next

      .row.row-cols-2.row-cols-md-4.row-cols-lg-4.g-4
        .col: img.img-fluid.rounded-3(src='/assets/img/l1.png' alt='#')
        .col: img.img-fluid.rounded-3(src='/assets/img/l2.png' alt='#')
        .col: img.img-fluid.rounded-3(src='/assets/img/l3.png' alt='#')
        .col: img.img-fluid.rounded-3(src='/assets/img/l4.png' alt='#')

      .pt-5.mobile-app-section(v-if='divInstalarApp')
        .bg-light.rounded.px-4.pt-4.px-md-4.pt-md-2.px-lg-5.pt-lg-2.pb-0
          .row.justify-content-center.align-items-center.app-2.px-lg-4
            .col-md-7.px-lg-5
              .text-md-start.text-center
                h1.fw-bold.mb-2.text-dark eBIZ Go a la mano
                .m-0.text-muted para descargar la aplicación a tu telefono presiona el siguiente boton
              .text-md-start.text-center.mt-2.mt-md-1.pb-md-4.pb-lg-5
                button#btnInstallApp.btn.btn-warning.rounded-pill.py-2.px-4.border-0(type='button' @click="instalarApp()") Instalar
            .col-md-5.pe-lg-5.mt-3.mt-md-0.mt-lg-0
              img.img-fluid(alt='#' src='assets/img/mobile-app.png')

  .container.py-4
    .text-center.mt-5(v-if='procesando')
      .spinner-border(role='status')
        span.visually-hidden Cargando...
      p.mb-0.mt-2 Cargando

    .row.row-cols-1.row-cols-md-2.g-3.pb-5
      .col(v-for="t in tiendas" :key="t.id")
        .bg-white.listing-card.shadow-sm.rounded-3.p-1.position-relative.d-flex.align-items-center.h-100
          .img-categoria(v-if='t.imagen_base64')
            img.img-fluid.rounded-3(:src='`data:image/png;base64,${t.imagen_base64}`' alt='...')
          img.img-fluid.rounded-3(v-else src='/assets/img/shop.jpg' alt='...')
          .pt-3.ms-3
            h6.card-title.fw-bold.mb-1 {{ t.display_name }}
            p.card-text.text-muted.mb-0
              i.bi.bi-geo-alt.me-1
              span {{ t.idTenant.address }}
            p.card-text.text-muted
              i.bi.bi-telephone.me-1
              span {{ t.idTenant.phone }}
          a.stretched-link(:href='`${url}/${t.id}`')
</template>

<script>
export default {
  data: () => ({
    rows: [],
    procesando: true,
    search: '',
    url: '',
    divInstalarApp: false,
    installPrompt: null
  }),

  computed: {
    tiendas() {
      if (this.search.length) {
        return this.rows.filter(t => t.display_name.toLowerCase().includes(this.search.toLowerCase()))
      }
      return this.rows;
    }
  },

  created() {
    this.url = `${location.protocol}//${location.host}`
    this.axios.get("/go-empresa/tiendas").then(({ data }) => {
      // console.log(data);
      this.rows = data
      this.procesando = false
    }).catch(err => {
      console.dir(err)
      this.procesando = false
    })

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installPrompt = e
      this.divInstalarApp = true
    });
  },

  methods: {
    async instalarApp() {
      if (this.installPrompt !== null) {
        this.installPrompt.prompt()
        const { outcome } = await this.installPrompt.userChoice
        if (outcome === 'accepted') {
          this.installPrompt = null
          this.divInstalarApp = false
        }
      }
    }

  }
}
</script>